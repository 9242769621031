import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import ReactMarkdown from 'react-markdown';
import Helmet from "react-helmet";

import Footer from "../components/common/footer.jsx";
import projects from "../data/projects.js";
import SEO from "../data/seo";
import "./styles/homepage.css";

const projectsBuilt = projects.built;
const projectsDesigned = projects.designed;

const Homepage = () => {
    const currentSEO = SEO.find((item) => item.page === "home");
    return (
        <React.Fragment>
            <Helmet>
				<title>Azerty Labs</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>
            <div className="bg-white text-black font-sans min-h-screen">
                <div className="flex justify-center bg-white m-24">
                    <FontAwesomeIcon icon={faCircleNotch} className="h-64 w-64 rotate-on-load"/ >
                </div>

                <div className="text-center mt-4">
                    <h1 className="text-5xl font-bold">Azerty Labs</h1>
                </div>

                <div className="text-center mt-4">
                    Building the Next Generation of Audio Tools for Musicians
                    <br/>
                    From Science to Dreams, From Dreams to Science
                </div>

                <div className="mt-12 px-4 lg:mx-32">
                    <h2 className="text-3xl font-semibold mb-6 text-center">Designed by Us</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 items-center justify-center">
                        {projectsDesigned.map((project) => (
                        <a
                        key={project.id}
                        href={project.url} // Link to the project's URL
                        target="_blank" // Open link in a new tab
                        rel="noopener noreferrer" // Security for opening in a new tab
                        className="relative block hover:scale-105 transition duration-300"
                        >
                                <div className="relative">
                                    <img
                                        src={project.imgSrc} // Use imgSrc from your data
                                        alt={project.name} // Use name from your data
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    <div className="absolute inset-0 flex top-0 justify-center mt-3">
                                        <span className="text-xl font-semibold text-white">{project.name}</span>
                                    </div>
                                    <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 p-4 rounded-lg">
                                        <div className="absolute inset-0 flex top-0 justify-center mt-3">
                                            <span className="text-xl font-semibold text-white">{project.name}</span>
                                        </div>
                                        <div className="text-white text-center">
                                            <ReactMarkdown>{project.description}</ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>

                <div className="mt-12 px-4 mb-3 lg:mb-12 lg:mx-32">
                    <h2 className="text-3xl font-semibold mb-6 text-center">Built by Us</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 items-center justify-center">
                        {projectsBuilt.map((project) => (
                        <a
                        key={project.id}
                        href={project.url} // Link to the project's URL
                        target="_blank" // Open link in a new tab
                        rel="noopener noreferrer" // Security for opening in a new tab
                        className="relative block hover:scale-105 transition duration-300"
                        >
                                <div className="relative">
                                    <img
                                        src={project.imgSrc} // Use imgSrc from your data
                                        alt={project.name} // Use name from your data
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    <div className="absolute inset-0 flex top-0 justify-center mt-3">
                                        <span className="text-xl font-semibold text-white">{project.name}</span>
                                    </div>
                                    <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 p-4 rounded-lg">
                                        <div className="absolute inset-0 flex top-0 justify-center mt-3">
                                            <span className="text-xl font-semibold text-white">{project.name}</span>
                                        </div>
                                        <div className="text-white text-center">
                                            <ReactMarkdown>{project.description}</ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="lg:p-11"></div>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default Homepage;
