import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import INFO from "../data/user";

const ComingSoon = () => {
	useEffect(() => {
		document.title = `ComingSoon | Azerty Labs`;
	}, []);

	return (
		<React.Fragment>
			<div className="min-h-screen bg-white flex flex-col items-center justify-center bg-gray-200 relative">
				<div className="text-center">
					<div className="text-5xl lg:text-5xl font-bold p-4">
						Coming Soon! {" "}
					</div>
					<div className="text-xl lg:text-xl p-6">
						I'm currently working on this project.
						<br />
						It's not available for purchase or open source yet. 
						<br />
						·
						<br />
						You can't wait? Let’s chat – message me! 🤗
					</div>
					<div className="mt-4 space-x-4 flex justify-center">
						{Object.entries(INFO.socials).map(([key, social]) => (
							<a
							key={key}
							href={social.link}
							target="_blank"
							rel="noopener noreferrer"
							className="text-gray-600 hover:text-gray-900 transition duration-150"
							>
							<FontAwesomeIcon icon={social.icon} className="text-2xl md:text-3xl" />
							</a>
						))}
						</div>
					<div className="p-10 w-full flex justify-center">
						<a href="/" className="btn btn-neutral text-white">
							Home page
						</a>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ComingSoon;
