import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const INFO = {
	socials: {
		github: {
			link:"https://github.com/chloelavrat",
			icon: faGithub
		},
		linkedin: {
			link:"https://www.linkedin.com/in/clavrat",
			icon: faLinkedin
		},
		instagram: {
			link:"https://instagram.com/c.lavrat",
			icon: faInstagram
		},
		website:{
			link: "https://www.chloelavrat.com/",
		}
	},
}

export default INFO;