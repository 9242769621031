
const projects = {
    designed: [
        { 
            id: 1,
            name: "Pronaos",
            imgSrc: "projects/pronaos.png",
            url: "comingsoon",
            description: "The **Beatlejuice** eurorack module is a USB-MIDI to CV/Gate/Clock interface with six versatile modes. It converts MIDI signals to CV (0-4V) and gate/trigger/clock (0-5V), and features a class-compliant design with easy mode switching."
        },{
            id: 2,
            name: "Gatekeeper",
            imgSrc: "projects/gatekeeper.png",
            url: "comingsoon",
            description: "The **Gatekeeper** eurorack module effortlessly integrates external audio into your modular setup. It converts signals between standard audio and Eurorack, with adjustable volume control for precise sound management."
        },{
            id: 3,
            name: "Beetlejuice",
            imgSrc: "projects/beetlejuice.png",
            url: "comingsoon",
            description: "The **Beatlejuce** module is a compact yet powerful PSU board designed for eurorack synthesizers. It delivers **+5V @4A, +12V @3A, and -12V @2A**, ensuring stable power in a credit size format."
        }
    ],
    built: [
        {
            id: 1,
            name: "Europi",
            imgSrc: "projects/europi.png",
            url: "comingsoon",
            description: "The **EuroPi** eurorack module by AllenSynth is a user-programmable device with a Raspberry Pi Pico. It features 6 CV outputs, 2 knobs, 2 buttons, 1 digital input, 1 analog input, and an OLED display, all controlled via custom Python code."
        },{
            id: 2,
            name: "Speaker",
            imgSrc: "projects/speaker.png",
            url: "comingsoon", 
            description: "The **Speaker** eurorack by Clacktronics Module lets you add a speaker to your Eurorack setup for direct audio monitoring and standalone performance."
        },{
            id: 3,
            name: "Reverb",
            imgSrc: "projects/reverb.png",
            url: "comingsoon",
            description: "The **Reverb** eurorack module by Music Thing Modular is a DIY mono spring reverb module with a clean, high-fidelity sound. It supports both electromechanical and solid-state tanks, offering blend, feedback, and crossfader controls."
        }
    ]
};

export default projects;

