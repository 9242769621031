const SEO = [
	{
		page: "home",
		description:
			"Azerty Labs | IBuilding the Next Generation of Audio Tools for Musicians | From Science to Dreams, From Dreams to Science",
		keywords: [],
	},
];

export default SEO;
