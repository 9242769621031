import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import './App.css';

import { TRACKING_ID } from "./data/tracking";
import Homepage from "./pages/homepage";
import ComingSoon from "./pages/ComingSoon";

function App() {
	useEffect(() => {
		if (TRACKING_ID !== "") {
			ReactGA.initialize(TRACKING_ID);
		}
	}, []);

  return (
    <div className="App">
			<Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
      </Routes>
    </div>
  );
}

export default App;
