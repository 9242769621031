import React, { useState } from "react";
import "./styles/footer.css";

import INFO from "../../data/user";

const Footer = () => {
	const [currentYear] = useState(new Date().getFullYear());

	return (
		<React.Fragment>
			<div className="flex-grow mt-10 mb-6 "></div>
			<footer className="bg-gray-900 text-white py-6 rounded-md w-auto mb-2 mx-4 lg:mb-5 lg:mx-36">
				<div className="container mx-auto text-center">
					<div className="flex justify-center lg:space-x-6">
						<ul className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
							<li>
								<a href={INFO.socials.github.link} className="hover:font-bold">
									GitHub
								</a>
							</li>
							<li>
								<a href={INFO.socials.linkedin.link} className="hover:font-bold">
									Linkedin
								</a>
							</li>
							<li>
								<a href={INFO.socials.instagram.link} className="hover:font-bold">
									Instagram
								</a>
							</li>
							<li>
								<a href={INFO.socials.website.link} className="hover:font-bold">
									Chloé Lavrat
								</a>
							</li>
						</ul>
					</div>
					<div className="mt-2 white-space: pre-wrap">
						<p>
							&copy; Azerty Labs {currentYear} - All rights
							reserved
						</p>
					</div>
				</div>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
